<template lang="pug">
div(class='group flex' :data-id='id' :data-selected='selected' role='button' tabindex='1')
  div(class='relative flex flex-1 flex-col overflow-hidden rounded bg-white p-2')
    div(class='relative aspect-square w-full rounded-md bg-grey-50 p-4' :class='selected ? "bg-blue-50" : "bg-grey-50"')
      slot(name='image')
        img(v-image-lazy :data-src='image' data-cloudinary='f_auto/t_md' class='pointer-events-none h-full w-full object-contain mix-blend-multiply')
      slot 
    div(class='flex pt-2')
      div(class='flex min-w-0 flex-1 gap-4')
        slot(name='title' :title='title')
          router-link(class='link-dark flex-1 text-sm leading-tight' :to='to') {{ title }}
        span(class='font-lato text-sm' :data-tooltip='$t("retailPriceNetString")') {{ retailPriceNetString }}
    div(class='flex items-center gap-4')
      div(class='flex-1 truncate pr-2 text-sm text-text-light') {{ brand }}
      slot(name='afterBrand')
    div(class='absolute left-5 top-5 hidden items-center group-hover:flex group-focus:flex group-active:flex' :class='selected ? "!flex" : ""')
      checkbox-icon(:value='selected')
</template>

<script lang="ts">
import CheckboxIcon from './Control/CheckboxIcon.vue'
import { useVModel } from '@vueuse/core'
import { defineComponent } from 'vue'
import { type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const ProjectProductGridItem = defineComponent({
  components: {
    CheckboxIcon,
  },
  props: {
    id: { type: String, required: true },
    title: String,
    image: String,
    brand: String,
    retailPriceNetString: String,
    to: { type: Object as PropType<RouteLocationRaw>, required: true },
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    return {
      selected: useVModel(props, 'modelValue', emit),
    }
  },
})
export default ProjectProductGridItem
</script>
